import Macy from 'macy/dist/macy';
import * as Utils from './utils';

'use strict';

const initialized = new WeakMap();

const registerRecalc = (macy) => {
    const recalc = () => { macy.recalculate(true, true); };
    setTimeout(recalc, 1);
    document.addEventListener('DOMContentLoaded', recalc);
    window.addEventListener('load', recalc);
    document.documentElement.addEventListener('turbo:render', recalc);
    document.documentElement.addEventListener('turbo:frame-render', recalc);
    document.documentElement.addEventListener('turbo:load', recalc);
    document.documentElement.addEventListener('turbo:frame-load', recalc);

    macy.runOnImageLoad(function () {
        macy.recalculate(true, true);
    }, true);
};

Utils.foreachElement('.eventlist--program .event-teaser', (teaser) => {
    const container = teaser.closest('.eventlist__items');
    let macy = null;

    if (initialized.has(container)) {
        macy = initialized.get(container);
        macy.recalculate(true, true);
    } else {
        macy = Macy({
            container: container,
            columns: 1,
            margin: {
                x: 16,
                y: 40
            },
            mobileFirst: true,
            breakAt: {
                800: {
                    columns: 2
                },
                1400: {
                    columns: 3,
                }
            }
        });

        initialized.set(container, macy);

        registerRecalc(macy);
    }
});

Utils.foreachElement('.mod_article.article-layout--teaser, .page-teasers__teasers, .mod_event_teaser .eventlist__items', (article) => {
    let macy = null;

    if (initialized.has(article)) {
        macy = initialized.get(article);
        macy.recalculate();
    } else {
        macy = Macy({
            container: article,
            columns: 1,
            margin: {
                x: 20,
                y: 40
            },
            mobileFirst: true,
            breakAt: {
                800: {
                    columns: 2
                },
                1400: {
                    columns: 3,
                }
            }
        });

        initialized.set(container, macy);

        registerRecalc(macy);
    }
});
